<template>
    <div class="myCollect">
        <!-- 我的收藏 -->
        <UserMsg class="userMsg_component" :userMsg="userMsg" />
        <div class="myCollect_content">
            <PartitionTitle class="partitionTitle" title="我的收藏" />
            <div v-if="defaultPageStatus">
                <el-scrollbar ref="scrollbarMax" class="myCollect_scorll contentScorll_max" >
                    <div class="myCollect_list">
                        <div v-for="(item, index) in playingListData" :key="index" >
                            <!-- 收藏图标 -->
                            <div
                                class="list_collect"
                                @click="collectCgStatus"
                                data-type="list_collect"
                                :data-id="item.game_id">
                                <i></i>
                            </div>
                            <!-- 游戏图片 -->
                            <div class="list_img" @click="routeDetail(item.game_id)">
                                <img :src="item.game_logo" />
                            </div>
                            <!-- 游戏内容 -->
                            <div class="list_content">
                                <div>
                                    <p :title="item.game_name">
                                        {{ item.game_name.length > 6? item.game_name.substr(0, 6) +"..." : item.game_name }}
                                    </p>
                                    <p>
                                        已玩{{ item.game_time.hours }}小时{{ item.game_time.minutes }}分钟
                                    </p>
                                </div>
                                <!-- 启动按钮 -->
                                <button
                                    class="startBtn"
                                    @click="startGame(item.game_id)">
                                    启动云游戏
                                </button>
                            </div>
                        </div>
                        <div class="seizeSeat_box"></div>
                    </div>
                    <div v-if="loading" class="list_loading"></div>
                </el-scrollbar>
                <el-scrollbar ref="scrollbarMin" class="myCollect_scorll contentScorll_min" :style="'height:' + minScorllHeight">
                    <!-- 小屏 -->
                    <div class="myCollect_min">
                        <div v-for="(item, index) in playingListData" :key="index">
                            <!-- 游戏图片 -->
                            <div
                                class="min_image"
                                @click="routeDetail(item.game_id)"
                                :data-id="item.game_id">
                                <img :src="item.game_logo" />
                            </div>
                            <!-- 游戏内容 -->
                            <div class="min_content">
                                <p>{{ item.game_name }}</p>
                                <p v-if="item.game_time.hours != 0">
                                    已玩{{ item.game_time.hours }}小时{{ item.game_time.minutes }}分钟
                                </p>
                                <p v-else>
                                    已玩{{ item.game_time.minutes }}分钟
                                </p>
                            </div>
                            <!-- 启动按钮 -->
                            <button
                                class="collectBtn"
                                @click="collectCgStatus"
                                data-type="collectBtn"
                                :data-id="item.game_id">
                                取消收藏
                            </button>
                        </div>
                    </div>
                    <div v-if="loading" class="list_loading"></div>
                </el-scrollbar>
            </div>
            <div v-else class="defaultPage">
                <div class="defaultPage_content">
                    <img src="@/assets/images/user/bg-default-collect.png" />
                    <p>您还没有任何收藏</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import UserMsg from '@/components/officialWebsite/UserMsg';
import launchGameUtil from "@/components/cloudGameLauncher/launchGameUtil";
import PartitionTitle from '@/components/officialWebsite/PartitionTitle';

export default {
    name: "MyCollect",
    props: {
        userMsgStatus: {
            //设置点击后加载请求
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            userMsg: {
                avatar: "", //用户头像
                name: "", //用户名
                age: 0, //用户年龄
                sex: 1, //性别
            },
            minScorllHeight: "100px",
            page: 1, //统计页数
            loading: false, //加载动画
            playingListData: [], //游戏记录列表
            defaultPageStatus: true,
            thisTimerId: null,
        };
    },
    watch: {
        //  获取用户与试玩数据
        userMsgStatus(state) {
            if (!state) return;
            // 点击后获取存储在store 的基础用户信息
            this.userMsg = this.$store.state.login.user;
            // 发送请求获取游戏记录
            this.loading = true;
            this.getMyCollectData();
        },
    },
    mounted() {
        this.scrollMore(this.$refs.scrollbarMax.wrap);
        this.scrollMore(this.$refs.scrollbarMin.wrap);
    },
    methods: {
        routeDetail(gameId){
            this.$router.push({path: '/game/' + gameId})
        },
        scrollMore(ele) {
            ele.onscroll = () => {
                if ( ele.scrollTop + ele.clientHeight >= ele.scrollHeight - 1 && !this.loading ) {
                    ele.scrollTop = ele.scrollTop - 1;

                    this.loading = true;
                    this.getRecordData();
                }
            };
        },
         startGame(gameId){
            launchGameUtil.startGameReview(gameId, this);
        },
        collectCgStatus(e) {
            let ele = e.currentTarget;
            let {id, type} = ele.dataset,
                name = ele.className,
                status = name.includes("cancel");

                if (type == "collectBtn") {
                    ele.innerText = status ? "取消收藏" : "加入收藏" ;
                }
                ele.className = status? type : `${name} cancel`;

            this.clickIncrement(status, id);
        },
        clickIncrement(status, id) {
            if (typeof this.thisTimerId != null) {
                clearTimeout(this.thisTimerId);
            }

            this.thisTimerId = setTimeout(() => {
                //  收藏与取消请求
                this.$API[status ? "collectionStatue" : "collectionNoStatue" ]({
                    game_id: id,
                }).then((response) => {
                    let res = response.data;
                    if (res.status) {
                        this.$store.commit("MESSAGE_TYPE", true);
                        //取消与加入收藏提示
                        status
                        ?this.$store.commit("MESSAGE_TEXT", "加入收藏成功")
                        :this.$store.commit("MESSAGE_TEXT", "取消收藏成功");
                    }
                });
            }, 500);
        },
        getMyCollectData() {
            this.$API.getMyCollectData({ page: this.page }).then((response) => {
                let res = response.data;
                if (res.status) {
                    if (res.data.list.length > 0) {
                        this.playingListData = [ ...this.playingListData, ...res.data.list,];
                        // 小屏列表小于，不设置滚动，大于 X 个就设置滚动
                        this.minScorllHeight =
                            res.data.list.length < 6
                            ? res.data.list.length * 72 + "px"
                            : (this.minScorllHeight = "466px");

                        // 最后一页销毁滚动监听
                        if (this.page == res.data.meta.last_page) {
                            this.$refs.scrollbarMax.wrap.onscroll = null;
                            this.$refs.scrollbarMin.wrap.onscroll = null;
                        }
                        this.page += 1;
                    } else {
                        // 进入无数据后
                        this.minScorllHeight = "350px";
                        this.defaultPageStatus = false;
                    }
                    this.loading = false;
                }
            });
        },
    },
    components: {
        UserMsg,
        PartitionTitle,
    },
};
</script>

<style lang="scss" scoped>
.myCollect {
    font-weight: 400;
    padding: 0 40px;
    font-family: "PingFangSC-Regular", sans-serif;

    > :nth-child(1) {
        margin-top: 5px;
    }
    // 记录列表
    // 滚动设置
    .myCollect_scorll {
        width: 100%;
        height: 580px;
        // 内容列表
        .myCollect_list {
            display: flex;
            flex-wrap: wrap;
            > div {
                width: 264px;
                height: 222px;
                margin-top: 10px;
                border-radius: 8px;
                flex: none;
                overflow: hidden;
                position: relative;
                background-color: #fff;

                .list_collect {
                    width: 40px;
                    height: 40px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    border-radius: 0px 0px 0px 8px;
                    background-color: rgba(255, 116, 116, 0.79);
                    i {
                        width: 25px;
                        height: 25px;
                        margin: 7.5px;
                        display: block;
                        background-image: url("../../../assets/images/user/i-collect-heart.png");
                    }
                }
                .list_collect:hover {
                    cursor: pointer;
                }
                .list_collect.cancel {
                    background-color: rgba(230, 230, 230, 0.79);
                }
                > .list_img {
                    width: 264px;
                    height: 148px;
                    overflow: hidden;
                    img {
                        width: 100%;
                    }
                }
                > .list_img:hover{
                    cursor: pointer;
                }
                > .list_content {
                    width: 264px;
                    height: 74px;
                    padding: 0 16px;
                    display: flex;
                    justify-content: flex-start;
                    > div {
                        width: 136px;
                        height: 50px;
                        transform: translateY(24%);
                        p {
                            margin-bottom: 0;
                        }
                        > :nth-child(1) {
                            font-size: 18px;
                            font-weight: 500;
                            color: #d5a561;
                            font-family: PingFangSC-Medium, sans-serif;
                        }
                        > :nth-child(2) {
                            font-size: 12px;
                            color: #606062;
                        }
                    }

                    .startBtn {
                        width: 82px;
                        height: 34px;
                        padding: 2px;
                        font-size: 14px;
                        line-height: 20px;
                        margin-left: 16px;
                        border-radius: 4px;
                        border: none;
                        outline: none;
                        color: #fff;
                        transform: translateY(59%);
                        background: linear-gradient( 154deg, #fad8a1 0%,#e3af6d 100% );
                    }
                    .startBtn:hover {
                        background: #e3af6d;
                    }
                }
            }
            .seizeSeat_box {
                background-color: none;
                height: 0;
            }
        }
        // 小屏内容
        .myCollect_min {
            width: 100%;
            > div {
                width: 100%;
                height: 48px;
                margin-bottom: 24px;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                .min_image {
                    width: 48px;
                    height: 48px;
                    margin-right: 8px;
                    border-radius: 4px;
                    overflow: hidden;
                    img {
                        width: 100%;
                    }
                }
                .min_content {
                    > p {
                        margin-bottom: 0;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }
                    > :nth-child(1) {
                        // height: 20px;
                        font-size: 16px;
                        font-weight: 500;
                        color: #d5a561;
                        margin-bottom: 5px;
                    }
                    > :nth-child(2) {
                        // height: 14px;
                        font-size: 10px;
                        color: #606062;
                    }
                }
                .collectBtn {
                    width: 70px;
                    height: 34px;
                    font-size: 11px;
                    color: #606062;
                    outline: none;
                    margin-top: 8px;
                    margin-left: auto;
                    line-height: 0px;
                    border: 1px solid #e6e6e6;
                    border-radius: 3px;
                    background-color: #e6e6e6;
                }
                .collectBtn.cancel {
                    color: #fff;
                    background: linear-gradient( 154deg,#fad8a1 0%,#e3af6d 100% );
                }
            }
        }
    }
}

// 缺省图
.defaultPage {
    width: 100%;
    margin-top: 40px;
    // background-color: navy;
    .defaultPage_content {
        width: 260px;
        height: 240px;
        margin: 0 auto;
        text-align: center;
        color: #adadb0;
    }
}


//加载动画 
.list_loading {
    display: flex;
    margin: 0 auto;
    margin-top: 20px;
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid #D5A561;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

.list_loading.hide{
    display: none;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


@media screen and (min-width: 1200px) {
    .myCollect_list {
        > div, .list_content{
            width: 249px !important;
        }
        > div:nth-of-type(3n + 1) {
            margin-right: 8px;
        }
        > div:nth-of-type(3n + 2) {
            margin-left: 8px;
            margin-right: 8px;
        }
        > div:nth-of-type(3n + 3) {
            margin-left: 8px;
        }
    }
    .contentScorll_min {
        display: none;
    }
}
@media screen and (max-width: 1200px) and (min-width: 1000px) {
    .myCollect_list {
        > div:nth-of-type(2n + 1) {
            margin-right: 8px;
        }
        > div:nth-of-type(2n + 2) {
            margin-left: 8px;
        }
    }
    .contentScorll_min {
        display: none;
    }
}
@media screen and (max-width: 1000px) and (min-width: 700px) {
    .myCollect {
        .myCollect_scorll {
            .myCollect_list {
                justify-content: center;
                align-items: center;
                > div, .list_content{
                    width: 245px !important;
                }
                > div:nth-of-type(2n + 1) {
                    margin-right: 8px;
                }
                > div:nth-of-type(2n + 2) {
                    margin-left: 8px;
                }
            }
        }
    }
    .contentScorll_min {
        display: none;
    }
}
@media screen and (max-width: 700px) and (min-width: 500px) {
    .myCollect {
        padding: 0;
        .myCollect_scorll {
            padding: 0 16px;
            background-color: white;
            .myCollect_list {
                justify-content: center;
                align-items: center;
                > div:nth-of-type(2n + 1) {
                    margin-right: 8px;
                }
                > div:nth-of-type(2n + 2) {
                    margin-left: 8px;
                }
            }
        }
    }

    .partitionTitle,
    .myCollect .contentScorll_max,
    .userMsg_component {
        display: none;
    }
    .contentScorll_min {
        display: block;
    }
}
@media screen and (max-width: 500px) and (min-width: 350px) {
    .myCollect {
        padding: 0;
        .myCollect_scorll {
            padding: 0 16px;
            background-color: white;
            .myCollect_list {
                justify-content: center;
                align-items: center;
            }
        }
    }
    .partitionTitle,
    .myCollect .contentScorll_max,
    .userMsg_component {
        display: none;
    }
    .contentScorll_min {
        display: block;
    }
}
@media screen and (max-width: 350px) {
    .min_content {
        width: 50%;
    }
    .myCollect {
        padding: 0;
        .myCollect_scorll {
            padding: 0 16px;
            background-color: white;
            .myCollect_list {
                justify-content: center;
                align-items: center;
            }
        }
    }
    .partitionTitle,
    .myCollect .contentScorll_max,
    .userMsg_component {
        display: none;
    }
    .contentScorll_min {
        display: block;
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
